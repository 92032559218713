import { ChevronRightIcon } from "@heroicons/react/outline";
import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import Link from "next/link";

export default function HeroSection() {
  const herosectionContent =
    "Our team have been building well-designed, easy to use websites since 2018. Whether you're starting from scratch or wanting to grow your existing online presence, we offer a range of services including Website Design & Development, Mobile App Development, Search Engine Optimisation (SEO), Social Media Marketing (SMM).";

  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../assets/animation/heroSection.json"),
    });
    return () => anim.destroy();
  }, []);

  return (
    <div className="md:flex block w-full lg:min-h-[500px]">
      <div className="sm:w-1/2 md:mt-16 mt-12">
        <h1 className="md:text-header text-header-mbl font-sourcesans">
          Adapt Technology and<span className="text-orange"> <br className="hidden md:block"/>Grow </span>your
          business
        </h1>
        <p className="text-primary-500 md:text-body text-body-mbl mt-10">{herosectionContent}</p>
        <div className="mt-10">
          <Link href={`/contact`} passHref>
            <button
              className="whitespace-nowrap inline-flex items-center justify-center px-12 py-3 border border-transparent rounded-full shadow-sm text-h3 font-montserratB text-white bg-btn-gradient hover:cursor-pointer"
            >
              {`TALK TO US`} &nbsp;
              <ChevronRightIcon
                className="h-4 w-4"
                style={{ strokeWidth: "3px" }}
                aria-hidden="true"
              />
            </button>
          </Link>
        </div>
      </div>
      <div
        className="animation-container md:pl-[4rem] sm:w-1/2 md:scale-110 mt-20 md:mt-0"
        ref={animationContainer}
      />
    </div>
  );
}
